import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Appointments } from '../treatmentPlan.state'
import { RootState } from 'store'

interface Response {
  treatmentPlanId: number
  appointments: Appointments
}

interface StageDto {
  id?: number
  stageId: number
  toothId: number
  sortOrder: number
  appointmentId: number
  patientTreatmentId: number
}

export const saveStagesIntoAppointments = createAsyncThunk<Response, number>(
  'treatmentPlan/save-stages-to-appointments',
  async (treatmentPlanId, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState
      const appointments = Object.values(state.treatmentPlan.treatmentAppointments.appointments)

      const appointmentStages = appointments.reduce((acc, appointment) => {
        const appointmentStages = appointment.appointmentStage

        const stagesToReq = appointmentStages.reduce((stageAcc, stage) => {
          const newStageObject: StageDto = {
            stageId: stage.stageId,
            toothId: stage.toothId,
            sortOrder: stage.sortOrder,
            appointmentId: stage.appointmentId,
            patientTreatmentId: stage.patientTreatmentId,
          }
          if (typeof stage.id === 'number') {
            newStageObject.id = stage.id
          }

          stageAcc.push(newStageObject)

          return stageAcc
        }, [])

        acc = [...acc, ...stagesToReq]

        return acc
      }, [])

      const updateAppointments = appointments.map(({ id, sortOrder }) => ({ id, sortOrder }))

      const response = await axios.post('appointment-stage', {
        appointmentStages,
        treatmentPlanId,
        appointments: updateAppointments,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
