import { createSelector } from '@reduxjs/toolkit'
import { ILanguage } from 'components/TranslatorProvider/TranslatorProvider.types'
import { RootState } from 'store'

class PatientSelector {
  selectPersonalId = (state: RootState) => state.patient.patient?.personalId
  selectPatient = (state: RootState) => state.patient.patient
  selectTreatmentPlan = (state: RootState) => state.patient.treatmentPlan
  selectVideos = (state: RootState) => state.patient.videos
  selectClinicInfo = (state: RootState) => state.patient.loginInfo
  selectIsLoggedIn = (state: RootState) => state.patient.isLoggedIn
  selectIsAppointment = (state: RootState) => state.patient.appointments
  selectClinic = (state: RootState) => state.patient.clinic
  selectAppointments = createSelector(
    (state: RootState) => state.patient.appointments,
    (appointments) => {
      if (!appointments) return []

      return [...appointments].sort((first, second) => first.sortOrder - second.sortOrder)
    },
  )
  selectPriorityByTooth = createSelector(
    (state: RootState) => state.patient.treatmentPlan,
    (plan) => (toothId: number) => {
      if (!plan?.teeth) return 'none'
      const teeth = plan.teeth

      const selectedTooth = teeth[toothId]

      return selectedTooth.priority
    },
  )

  selectTotalTreatment = createSelector(
    (state: RootState) => state.patient.appointments,
    (appointments) => {
      if (!appointments) return { appointments: 0, duration: 0, price: 0, currency: '' }

      return appointments.reduce(
        (acc, appointment) => {
          if (appointment.appointmentStage) {
            const stageTotal = appointment.appointmentStage.reduce(
              (acc, stage) => {
                acc.currency = stage.stage.currency
                acc.price += stage.stage.price
                acc.duration += stage.stage.duration
                return acc
              },
              { currency: '', price: 0, duration: 0 },
            )
            acc.duration += stageTotal.duration
            acc.price += stageTotal.price
            acc.currency = stageTotal.currency
          }
          acc.appointments += 1

          return acc
        },
        {
          appointments: 0,
          duration: 0,
          price: 0,
          currency: '',
        },
      )
    },
  )
  selectPatientImage = (state: RootState) => {
    const image = state.patient.treatmentPlan?.treatmentImages
    const index = state.patient.selectedImageIndex

    if (image) {
      return image[index]
    }
  }
  selectPatientImagesTeethByIndex = createSelector(
    (state: RootState) => state.patient.treatmentPlan,
    (plan) => (index: number) => {
      if (!plan) return []
      const image = plan.treatmentImages[index]
      if (!image?.metadata?.teeth) return []

      return image.metadata.teeth
    },
  )

  selectPatientImages = (state: RootState) => state.patient.treatmentPlan?.treatmentImages
  selectActiveImageIndex = (state: RootState) => state.patient.selectedImageIndex
  selectTooth = (state: RootState) => state.patient.selectedTooth

  selectToothDescription = createSelector(
    (state: RootState) => state.patient.treatmentPlan,
    (plan) => (toothId: number, lang: ILanguage) => {
      if (!plan?.teeth || !toothId) return ''

      const selectedTooth = plan.teeth[toothId]

      return selectedTooth?.descriptionTranslate[lang] || selectedTooth.description
    },
  )

  selectToothDiagnosis = createSelector(
    (state: RootState) => state.patient.treatmentPlan,
    (plan) => (toothId: number) => {
      if (!plan?.teeth) return []

      return plan.teeth[toothId].patientDiagnoses || []
    },
  )

  selectToothArrayImages = (state: RootState) => {
    const images = state.patient?.treatmentPlan?.treatmentImages
    if (!images) return []

    const array = Object.values(images).reduce((acc, el) => {
      const teeth = el.metadata.teeth

      teeth.map((el) => acc.push(el.toothId))
      return acc
    }, [])

    return array
  }

  selectDoctors = (state: RootState) => {
    const clinic = state.patient.clinic
    if (!clinic) return []

    return clinic.doctors
  }

  selectEquipments = (state: RootState) => {
    const clinic = state.patient.clinic
    if (!clinic) return []

    return clinic.equipments
  }

  selectCases = (state: RootState) => state.patient.cases
  selectPatientToothType = (state: RootState) => state.patient.toothType
  selectPatientTeeth = (state: RootState) => state.patient.treatmentPlan?.teeth
}
export const patientSelector = new PatientSelector()
