import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { TreatmentPlan } from 'store/home/home.state'

interface Request {
  id: number
  name: string
}

export const changeTreatmentPlanName = createAsyncThunk<TreatmentPlan, Request>(
  'treatmentPlan/change-treatment-plan-name',
  async ({ id, name }, thunkAPI) => {
    try {
      const response = await axios.patch(`/treatment-plan/${id}`, { name })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
